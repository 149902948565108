import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { useForm } from 'react-hook-form'
import Loader from 'components/loader'
import { useGetInTouchLocation } from 'hooks/Forms/useGetInTouchLocation'
import AmazonSvg from 'assets/images/amazon_1.svg'
import GoogleSvg from 'assets/images/quote_images/google_1.svg'
import MicrosoftSvg from 'assets/svg/microsoft'
import YoutubeSvg from 'assets/svg/youtubesvg'
import BloombergSvg from 'assets/ads-assets/trusted-icons/bloomberg.svg'
import SpotifySvg from 'assets/ads-assets/trusted-icons/spotify.svg'
import WixSvg from 'assets/ads-assets/trusted-icons/wix.svg'
import HsbcSvg from 'assets/svg/hsbc'
import first from 'assets/ads-assets/247.svg'
import gurantee from 'assets/ads-assets/guarantee_usp.svg'
import business from 'assets/ads-assets/business.svg'
import ThankYouPopup from 'components/popup/thankYouPopup'
import formCommonPostData from 'utils/form-common-post-data'

const FirstAdComp = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { status, mutateAsync } = useGetInTouchLocation()

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    }
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset()
      setOpen(true)
    })
  }
  return (
    <div>
      <div className=" bg-banner-ads bg-no-repeat bg-cover py-10">
        {/* -----First Component----- */}
        <div className=" flex flex-wrap w-full px-3 sm:w-11/12 mx-auto justify-between 2xl:w-7/12">
          <div className="flex flex-col w-[600px]">
            <h1 className="text-[35px]  sm:px-0 sm:text-[40px] font-bold font-secondary">
              Website Translation <br /> Services
            </h1>

            <p className="text-[16px] font-sans py-4 sm:w-[452px]">
              Tomedes website translation services are top-tier in terms of
              translator’s coverage, training, and certification, and who are
              knowledgeable in over 120 languages, with a one-year quality
              guarantee.
            </p>
            <div className=" flex-col gap-y-4 pt-[10px] sm:flex hidden">
              <div className="flex gap-x-2">
                <img src={first} alt="" />
                <span className="font-secondary text-[20px] text-[#00173A]">
                  Available Online 24/7
                </span>
              </div>
              <div className="flex gap-x-2">
                <img src={gurantee} alt="" />
                <span className="font-secondary text-[20px] text-[#00173A]">
                  1-Year Guarantee
                </span>
              </div>
              <div className="flex gap-x-2">
                <img src={business} alt="" />
                <span className="font-secondary text-[20px] text-[#00173A]">
                  95,000 Business Customers
                </span>
              </div>
            </div>

            <div className="py-[2rem] sm:flex flex-col hidden">
              <p className="text-white font-sans font-semibold text-[16px]">
                Trusted by Satisfied Clients Worldwide
              </p>
              <div className="grid grid-cols-4 gap-6 pt-6 items-center">
                <img src={AmazonSvg} alt="" />
                <img src={GoogleSvg} alt="" />
                <MicrosoftSvg />
                <img src={WixSvg} alt="" />
                <YoutubeSvg />
                <img src={BloombergSvg} alt="" className="pt-[2px]" />
                <img src={SpotifySvg} alt="" />
                <HsbcSvg />
              </div>
            </div>
          </div>
          <ThankYouPopup open={open} setOpen={setOpen} />

          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" h-full px-6 sm:px-10 pt-6 sm:pt-8 w-[409px] bg-[#FFFFFF] pb-6 sm:pb-8 sm:mt-[37px]"
            style="box-shadow: 5px 5px 50px #004C7430; border-radius: 20px;"
          >
            <h2 className="text-[20px] sm:text-[22px] font-semibold font-secondary">
              <span className="text-orange">Get In Touch</span> With Our
              Official <br /> Translation Services Now
            </h2>
            <div className="flex flex-col pt-4">
              <input
                type="text"
                name="name"
                className="pl-2 py-2 rounded mt-2"
                placeholder="Name"
                style="border: 1px solid #D8D8D8;"
                {...register('name', {
                  required: true,
                })}
              />
              {errors.name && (
                <span className="text-red text-sm mb-[10px] font-sans mt-2">
                  This field is required.
                </span>
              )}

              <input
                type="text"
                placeholder="Email"
                name="email"
                className="pl-2 py-2 rounded mt-2"
                style="border: 1px solid #D8D8D8;"
                {...register('email', {
                  required: true,
                })}
              />
              {errors.email && (
                <span className="text-red text-sm font-sans mb-[10px] mt-2">
                  This field is required.
                </span>
              )}

              <textarea
                type="text"
                name="message"
                className="pl-2 py-2 rounded mt-2 min-h-[153px]"
                placeholder="Tell us more about your needs"
                style="border: 1px solid #D8D8D8;"
                {...register('message')}
              />
              {errors.message && (
                <span className="text-red text-sm font-primary mb-[10px] ">
                  This field is required
                </span>
              )}

              <button className="bg-orange text-white text-[18px] font-sans py-3 mt-4">
                {status === 'loading' ? <Loader /> : 'SUBMIT'}
              </button>
            </div>
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          </form>
          <div className=" flex-col gap-y-4 pt-8 sm:hidden flex justify-start">
            <div className="flex gap-x-2">
              <img
                src="https://tomedes.gumlet.io/frontend/images/location-template/call-center.svg"
                alt=""
              />
              <span className="font-secondary text-[20px] text-white ">
                Available Online 24/7
              </span>
            </div>
            <div className="flex gap-x-2">
              <img
                src="https://tomedes.gumlet.io/frontend/images/location-template/badge.svg"
                alt=""
              />
              <span className="font-secondary text-[20px] text-white">
                1-Year Guarantee
              </span>
            </div>
            <div className="flex gap-x-2">
              <img
                src="https://tomedes.gumlet.io/frontend/images/location-template/16-express.svg"
                alt=""
              />
              <span className="font-secondary text-[20px] text-white">
                95,000 Business Customers
              </span>
            </div>
          </div>
          <div className="pt-[3rem] text-white sm:hidden flex flex-col">
            <p className="text-[16px] font-sans font-semibold text-left">
              Trusted by Satisfied Clients Worldwide
            </p>
            <div className="grid grid-cols-3 gap-8 pt-6 px-2 pr-5">
              <img src={AmazonSvg} alt="" />
              <img src={GoogleSvg} alt="" />
              <MicrosoftSvg />
              <img src={WixSvg} alt="" />
              <YoutubeSvg />
              <img src={BloombergSvg} alt="" />
              <img src={SpotifySvg} alt="" />
              <HsbcSvg />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstAdComp
